import { memo, useEffect, useState } from "react"
import Mediacenter from "./Mediacenter"
import Media from "./Media";
import Number from "./Number";
import Blogfirstbox from "./Blogfirstbox";
import Headernewtop from "../NewSections/Headernewtop";
import Blogbanner from "./Blogbanner";
import Newfooter from "../NewSections/Newfooter";
import Blogsecondbox from "./Blogsecondbox";
import Blogthreebox from "./Blogthreebox";
import Mobcontroller from "../ContactButton/Mobcontroller";
import Contactbutton from "../ContactButton/Contactbutton";
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo";
import Blogbanners from "../Newservicesbox/Blog/Blogbanners";
import Functionmain from "../Functionmain";

function Blog(){
    const [index, setIndex]=useState(1)
    const menu=[
        {
            int:1,
            box:<Blogfirstbox/>,
        },
        {
            int:2,
            box:<Blogsecondbox/>,
        },
        {
            int:3,
            box:<Blogthreebox/>
        }
    ]
    const [num, setNum]=useState(menu)
    useEffect(()=>{
        const update=menu.filter((check)=>{
            return check.int === index
        })
        setNum(update)
    })
    useEffect(()=>{
        Functionmain()
    },[])
    return (<>
    <Mobcontroller/>
    <Contactbutton/>
    <Sidelogo/>
    <Headernewtop/>
    <Blogbanners/>
    {/* <Mediacenter/> */}
    <section className="my-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                {
        num.map((elem)=>{
            const {box}=elem
            {
                return (<>
                {box}
                </>)
            }
        })
    }
                </div>
                <div className="col-lg-4">
                    <Mediacenter/>
                    <Media/>
                </div>
            </div>
        </div>
    </section>
    {/* <Number index={index} setIndex={setIndex}/> */}
    {/* <Media/> */}
    <Newfooter/>
    </>
    )
}
export default memo(Blog);