import { memo, useContext } from "react"
import LazyLoad from "react-lazy-load"
import { Link } from "react-router-dom"
import { data3 } from "../../App";

function Blogfirstbox(){
    const changers=useContext(data3);
    const menu=[
        {
            img:"../photo/ss9.jpg",
            name:"Laser Treatment for Varicose Veins and Thyroid Ablation",
            content:"As medical technology advances, laser treatments are transforming how we manage various health conditions...",
        },
        {
            img:"../photo/ss7.jpg",
            name:"The Ultimate Guide to Varicose Vein Laser Treatment",
            content:"Varicose veins are a common concern that affects millions, causing both aesthetic discomfort and, in many cases, significant pain...",
        },
        {
            img:"../photo/ss6.jpg",
            name:"Revolutionary Laser Therapies",
            content:"Laser technology is changing the face of modern medicine, providing minimally invasive, precise treatments for conditions...",
        },
        {
            img:"../photo/ss5.jpg",
            name:"Endovenous Laser Treatment (EVLT) for Varicose Veins",
            content:"TVaricose veins affect millions of people worldwide, leading to discomfort, swelling, and visible, bulging veins that often affect confidence...",
        },
    ]
    return (<>
    <section className="mb-0">
        <div className="container">
                <div style={{textAlign:"center"}} className="mediaheader mb-4">
                    <h6>MEDIA CENTER</h6>
                    <h1>latest Updates & Articles</h1>
                </div>
            <div className="row">
            {
                        menu.map((elem)=>{
                            const {img, name}=elem
                            {
                                return (<>
                                    <div style={{height:"auto"}} className="col-md-6 col-12 mb-4">
                                        <div style={{backgroundColor:"#dbeafe", height:"100%"}}>
                                        <LazyLoad>
                                            <img style={{width:"100%"}} src={img} alt="" />
                                        </LazyLoad>
                                        <div className="chafirst">
                                            <h5>{name}</h5>
                                            <Link to="/subblog" onClick={()=>{
                            changers(name)
                        }} className="noreinfo absuinfo">MORE INFO</Link>
                                        </div>
                                        </div>
                                    </div>
                                </>)
                            }
                        })
                    }
            </div>
        </div>
    </section>
    </>)
}
export default memo(Blogfirstbox)